import {Routes} from '@angular/router';
import {LOGIN_REDIRECT, NOT_FOUND_REDIRECT} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...LOGIN_REDIRECT,
  {
    path: 'login',
    loadChildren: () => import('../landing-pages/login/login.routes'),
  },
  {
    path: 'registration',
    loadChildren: () => import('../landing-pages/registration/registration.routes'),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../main-pages/dashboard/dashboard.routes'),
  },
  {
    path: 'reports/ecommerce/activeusers',
    loadChildren: () => import('../main-pages/users/users.routes'),
  },
  {
    path: 'reports/ecommerce/genericreports',
    loadChildren: () => import('../main-pages/generic-report-list/generic-report-list.routes'),
  },
  {
    path: 'reports/ecommerce/genericreport/:event',
    loadChildren: () => import('../main-pages/generic-report-create/generic-report-create.routes'),
  },
  {
    path: 'reports/ecommerce/scheduledreports',
    loadChildren: () => import('../main-pages/scheduled-reporting/scheduled-reporting.routes'),
  },
  {
    path: 'reports/ecommerce/scheduledreport/:event',
    loadChildren: () => import('../main-pages/scheduled-reporting-create/scheduled-reporting-create.routes'),
  },
  {
    path: 'reports/ecommerce/:reportName',
    loadChildren: () => import('../main-pages/reports/reports.routes'),
  },
  {
    path: 'reports/tradein/:reportName',
    loadChildren: () => import('../main-pages/trade-reports/trade-reports.routes'),
  },
  {
    path: 'accounts',
    loadChildren: () => import('../main-pages/accounts/accounts.routes'),
  },
  {
    path: 'accounts/details/:id',
    loadChildren: () => import('../main-pages/account-detail/account-detail.routes'),
  },
  {
    path: 'settings/users',
    loadChildren: () => import('../main-pages/backoffice-users/backoffice-users.routes'),
  },
  {
    path: 'collection/list',
    loadChildren: () => import('../main-pages/collections/collections.routes'),
  },
  {
    path: 'collection/close/:id/:poId',
    loadChildren: () => import('../main-pages/collection-close/collection-close.routes'),
  },
  {
    path: 'collection/dayscheduling',
    loadChildren: () => import('../main-pages/collection-day-scheduling/collection-day-scheduling.routes'),
  },
  {
    path: 'collection/calllist',
    loadChildren: () => import('../main-pages/call-list/call-list.routes'),
  },
  {
    path: 'collection/dataimport',
    loadChildren: () => import('../main-pages/collection-data-import/collection-data-import.routes'),
  },
  {
    path: 'collection/dataimport/import',
    loadChildren: () => import('../main-pages/collection-data-file-import/collection-data-file-import.routes'),
  },
  {
    path: 'collection/dataexport',
    loadChildren: () => import('../main-pages/collection-data-export/collection-data-export.routes'),
  },
  {
    path: 'collection/payments',
    loadChildren: () => import('../main-pages/collection-payments/collection-payments.routes'),
  },
  {
    path: 'tradein/inventorylisttrade',
    loadChildren: () => import('../main-pages/inventory-list-trade/inventory-list-trade.routes'),
  },
  {
    path: 'tradein/inventorylisttrade/import',
    loadChildren: () => import('../main-pages/trade-import/trade-import.routes'),
  },
  {
    path: 'settings/locations',
    loadChildren: () => import('../main-pages/location-list/location-list.routes'),
  },
  {
    path: 'settings/location/import',
    loadChildren: () => import('../main-pages/location-import/location-import.routes'),
  },
  {
    path: 'settings/location/:event',
    loadChildren: () => import('@backoffice/components/main-pages/location-create//location-create.routes'),
  },
  {
    path: 'settings/promos',
    loadChildren: () => import('../main-pages/promos/promos.routes'),
  },
  {
    path: 'settings/promos/:event',
    loadChildren: () => import('../main-pages/promo-create/promo-create.routes'),
  },
  {
    path: 'tradein/paymentfiles',
    loadChildren: () => import('../main-pages/hsbc-files/hsbc-files.routes'),
  },
  {
    path: 'settings/systemjobs',
    loadChildren: () => import('../main-pages/system-jobs/system-jobs.routes'),
  },
  {
    path: 'settings/systemmessages',
    loadChildren: () => import('../main-pages/release-messages/release-messages.routes'),
  },
  {
    path: 'settings/releasemessages/:event',
    loadChildren: () => import('../main-pages/release-message-create/release-message-create.routes'),
  },
  {
    path: 'settings/loginmessages/:event',
    loadChildren: () => import('../main-pages/login-message-create/login-message-create.routes'),
  },
  {
    path: 'settings/searchsettings',
    loadChildren: () => import('../main-pages/in-site-search-settings/in-site-search-settings.routes'),
  },
  {
    path: 'settings/searchsettings/:event',
    loadChildren: () => import('../main-pages/query-create/query-create.routes'),
  },
  {
    path: 'settings/features',
    loadChildren: () => import('../main-pages/features/features.routes'),
  },
  ...NOT_FOUND_REDIRECT,
];
