import {Injectable} from '@angular/core';
import {MenuRoutesService} from '@backoffice/services/menu-routes/menu-routes.service';
import {UserDataService} from '@backoffice/services/user-data/user-data.service';
import {BackofficeRoles} from '@common/enum/backofficeRoles.enum';
import {LocalStorageModuleTypes} from '@common/enum/localStorageModuleTypes';
import {UserRoles} from '@common/statics/userRoles';
import {AuthCommon} from '@common/ts/authCommon';
import {BackofficeURLs} from '@common/ts/backofficeFormInterfaces';
import {Observable, of, switchMap} from 'rxjs';

@Injectable()
export class AuthService extends AuthCommon<BackofficeURLs, BackofficeRoles> {

  constructor(public routerSrv: MenuRoutesService,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

  override authHandler(roles: BackofficeRoles[]): Observable<boolean> {
    if (UserRoles.hasRole(roles)) {
      return this.userDataService.userDataCheck().pipe(switchMap((checked) => {
        if (checked) {
          this.userDataService.httpUpdate.next(false);
          return this.userDataService.localSSrv.saveLocalStorageData(LocalStorageModuleTypes.Backoffice).pipe(
            switchMap(() => of(true)));
        }
        return of(false);
      }));
    }
    return this.goToLogin();
  }

  goToLogin(): Observable<boolean> {
    this.routerSrv.menuRoutes('login');
    return of(false);
  }

  removeData(): void {
    this.userDataService.dataRemover();
    this.routerSrv.menuRoutes('login');
  }

}
