import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {UserDataService} from '@backoffice/services/user-data/user-data.service';
import {CanDeactivateService} from '@common/services/can-deactivate/can-deactivate.service';
import {AppCommon} from '@common/ts/appCommon';
import {BackofficeURLs} from '@common/ts/backofficeFormInterfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends AppCommon<BackofficeURLs> {

  constructor(public activateSrv: CanDeactivateService<BackofficeURLs>,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

}
