import ecommerceReports from '@common/json/backofficeEcommerceReports';
import backofficeTradeInReports from '@common/json/backofficeTradeInReports';
import tradeInReports from '@common/json/tradeInReports';

export default {
  ...ecommerceReports,
  ...backofficeTradeInReports,
  ...tradeInReports,
  dashboard: 'Dashboard',
  reports: 'Reports',
  ecommerce: 'Ecommerce',
  trade: 'Trade-in',
  collection: 'Collection',
  collections: 'Collection Requests',
  inventoryListTrade: 'Inventory List Trade',
  accounts: 'Accounts',
  boUsers: 'Users',
  promos: 'Promos',
  systemJobs: 'System jobs',
  collectionDates: 'Collection day scheduling',
  callList: 'Call list',
  dataImport: 'Data import',
  dataExport: 'Data export',
  systemMessages: 'System messages',
  searchSettings: 'In Site Search',
  settings: 'Settings',
  payments: 'Payments',
  soldItemsAmountReport: 'Sold items amount',
  activeUsers: 'Active users',
  genericReports: 'Generic reports',
  scheduledReports: 'Scheduled reports',
  features: 'UI Feature Manager',
  locationManagement: 'Location management',
  locationImport: 'Import Locations',
};
