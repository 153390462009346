import {Injectable} from '@angular/core';
import {getSettingsSubOptions} from '@backoffice/components/plugins/main-menu/main-menu.variables';
import {BackofficeStorageData, BackofficeURLs} from '@common/ts/backofficeFormInterfaces';
import {MenuOverlaySubOptions} from '@common/ts/interfaces';
import {UserDataCommon} from '@common/ts/userDataCommon';

@Injectable()
export class UserDataService extends UserDataCommon<BackofficeURLs, BackofficeStorageData> {

  getSettingsSubOptions(): MenuOverlaySubOptions<BackofficeURLs>[] {
    return getSettingsSubOptions();
  }

  dataRemover(): void {
    this.mainDataRemover();
  }

}
