export enum RelativeValues {
  Today = 0,
  Yesterday = 1,
  ThisWeek = 2,
  ThisMonth = 3,
  LastWeek = 4,
  LastMonth = 5,
  ThisYear = 6,
  LastYear = 7,
  OnDate = 8,
  Last7Days = 9,
  Last30Days = 10,
  Last60Days = 11,
  Last90Days = 12,
}
