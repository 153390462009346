export default {
  saveSuccess: 'Collection successfully saved.',
  saveFailed: 'Collection save failed.',
  collectionLoadFailed: 'Collection load failed.',
  collectionModifySuccess: 'Collection item successfully modified.',
  collectionModifyFailed: 'Failed to modify collection item.',
  collectionCancelSuccess: 'Collection successfully canceled.',
  collectionCancelFailed: 'Failed to cancel collection.',
  collectionCloseSuccess: 'Collection successfully closed.',
  collectionCloseFailed: 'Failed to close collection.',
  markAsFailedSuccess: 'Collection successfully marked as failed.',
  markAsFailedFailed: 'Failed to mark as failed.',
  voidSuccess: 'Collection successfully voided.',
  voidFailed: 'Failed to void collection.',
};
