import {BackofficeRoles} from '@common/enum/backofficeRoles.enum';
import {BACKOFFICE_ADMIN_AND_AGENT_ROLES} from '@common/globals/backofficeAdminAndAgentRoles';
import {BACKOFFICE_ADMIN_ROLES} from '@common/globals/backofficeAdminRoles';
import {BACKOFFICE_ROLES} from '@common/globals/backofficeRoles';
import {HBSC_FILES_ROLES} from '@common/globals/hbscFilesRoles';
import {UserRoles} from '@common/statics/userRoles';
import {BackofficeURLs} from '@common/ts/backofficeFormInterfaces';
import {MenuOverlaySubOptions} from '@common/ts/interfaces';

import texts from './mainMenu';

// eslint-disable-next-line max-lines-per-function
export function getReportsSubOptions(): MenuOverlaySubOptions<BackofficeURLs>[] {
  const noBackofficeRoles = !UserRoles.hasRole(BACKOFFICE_ROLES),
    hasTradeOps = UserRoles.hasRole([BackofficeRoles.ROLE_BO_OPS]),
    hasTradeBO = UserRoles.hasRole([BackofficeRoles.ROLE_TRADE_BO]);
  return [{
    title: texts.ecommerce,
    options: [{
      name: texts.listedItemsReport,
      url: 'reports/ecommerce/listedItems',
      hidden: noBackofficeRoles,
    }, {
      name: texts.soldItemsReport,
      url: 'reports/ecommerce/soldItems',
      hidden: noBackofficeRoles,
    }, {
      name: texts.billingReport,
      url: 'reports/ecommerce/billingReport',
      hidden: noBackofficeRoles,
    }, {
      name: texts.billingDetailsReport,
      url: 'reports/ecommerce/billingDetailsReport',
      hidden: noBackofficeRoles,
    }, {
      name: texts.activeUsers,
      url: 'reports/ecommerce/activeusers',
      hidden: !UserRoles.hasRole([BackofficeRoles.ROLE_BO_MANAGER]),
    }, {
      name: texts.categorySales,
      url: 'reports/ecommerce/categorysales',
      hidden: noBackofficeRoles,
    }, {
      name: texts.genericReports,
      url: 'reports/ecommerce/genericreports',
      hidden: noBackofficeRoles,
    }, {
      name: texts.scheduledReports,
      url: 'reports/ecommerce/scheduledreports',
      hidden: noBackofficeRoles,
    }],
  }, {
    title: texts.trade,
    options: [{
      name: texts.trades,
      url: 'reports/tradein/trade',
      hidden: noBackofficeRoles,
    }, {
      name: texts.payments,
      url: 'reports/tradein/payment',
      hidden: !UserRoles.hasRole(HBSC_FILES_ROLES),
    }, {
      name: texts.rejectAccept,
      url: 'reports/tradein/qcrejectandaccept',
      hidden: noBackofficeRoles,
    }, {
      name: texts.userPerformance,
      url: 'reports/tradein/userperformance',
      hidden: hasTradeBO,
    }, {
      name: texts.tradeStatus,
      url: 'reports/tradein/tradestatus',
      hidden: hasTradeBO,
    }, {
      name: texts.scanReject,
      url: 'reports/tradein/scanreject',
      hidden: hasTradeBO,
    }, {
      name: texts.receiverReport,
      url: 'reports/tradein/receiver',
      hidden: hasTradeOps,
    }, {
      name: texts.opcByGradeReport,
      url: 'reports/tradein/opcbygrade',
      hidden: hasTradeOps,
    }, {
      name: texts.opcSplitConditionReport,
      url: 'reports/tradein/opcsplitcondition',
      hidden: hasTradeOps,
    }, {
      name: texts.orderReceiverUserReport,
      url: 'reports/tradein/orderreceiveruser',
      hidden: hasTradeOps,
    }, {
      name: texts.submittedToCompletedRateReport,
      url: 'reports/tradein/submittedtocompleted',
      hidden: hasTradeOps,
    }],
  }];
}

// eslint-disable-next-line max-lines-per-function
export function getSettingsSubOptions(): MenuOverlaySubOptions<BackofficeURLs>[] {
  const noAdminRoles = !UserRoles.hasRole(BACKOFFICE_ADMIN_ROLES),
    noAdminRolesOrTradeManager = noAdminRoles || UserRoles.hasRole([BackofficeRoles.ROLE_TRADE_BO]),
    noAdminOrAgentRoles = !UserRoles.hasRole(BACKOFFICE_ADMIN_AND_AGENT_ROLES),
    superAdminRoles = !UserRoles.hasRole([BackofficeRoles.ROLE_ADMIN]);
  return [{
    options: [{
      name: texts.systemJobs,
      url: 'settings/systemjobs',
      hidden: superAdminRoles,
    }, {
      name: texts.searchSettings,
      url: 'settings/searchsettings',
      hidden: superAdminRoles,
    }, {
      name: texts.promos,
      url: 'settings/promos',
      hidden: noAdminRolesOrTradeManager,
    }, {
      name: texts.systemMessages,
      url: 'settings/systemmessages',
      hidden: noAdminRolesOrTradeManager,
    }, {
      name: texts.boUsers,
      url: 'settings/users',
      hidden: noAdminRolesOrTradeManager,
    }, {
      name: texts.features,
      url: 'settings/features',
      hidden: !UserRoles.hasRole([BackofficeRoles.ROLE_ADMIN]),
    }, {
      name: texts.locationManagement,
      url: 'settings/locations',
      hidden: noAdminOrAgentRoles,
    }, {
      name: texts.locationImport,
      url: 'settings/location/import',
      hidden: noAdminRoles,
    }],
  }];
}

export function getCollectionSubOptions(): MenuOverlaySubOptions<BackofficeURLs>[] {
  const noBackofficeRoles = !UserRoles.hasRole(BACKOFFICE_ROLES),
    noAdminRoles = !UserRoles.hasRole(BACKOFFICE_ADMIN_ROLES),
    noAdminOrAgentRoles = !UserRoles.hasRole(BACKOFFICE_ADMIN_AND_AGENT_ROLES);
  return [{
    options: [{
      name: texts.collections,
      url: 'collection/list',
      hidden: noAdminOrAgentRoles,
    }, {
      name: texts.collectionDates,
      url: 'collection/dayscheduling',
      hidden: noBackofficeRoles,
    }, {
      name: texts.callList,
      url: 'collection/calllist',
      hidden: noAdminOrAgentRoles,
    }, {
      name: texts.dataImport,
      url: 'collection/dataimport',
      hidden: noAdminRoles,
    }, {
      name: texts.dataExport,
      url: 'collection/dataexport',
      hidden: noAdminRoles,
    }, {
      name: texts.payments,
      url: 'collection/payments',
      hidden: noAdminOrAgentRoles,
    }],
  }];
}

export function getTradeInSubOptions(): MenuOverlaySubOptions<BackofficeURLs>[] {
  return [{
    options: [{
      name: texts.inventoryListTrade,
      url: 'tradein/inventorylisttrade',
      hidden: !UserRoles.hasRole(BACKOFFICE_ROLES),
    },
    {
      name: texts.payments,
      url: 'tradein/paymentfiles',
      hidden: !UserRoles.hasRole(HBSC_FILES_ROLES),
    }],
  }];
}
